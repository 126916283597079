import 'bootstrap';
import './sass/main.scss';

// import 'vr-bg-video' from '../assets/vr-bg-video.mp4';

const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${
        window.innerHeight
    }px`);
};

let prevScrollpos = window.pageYOffset;
let navbar = document.getElementById('navbar');
let navButton = document.getElementById('nav-hamburger');
let ar = document.getElementById('ar');
let vr = document.getElementById('vr');
let mainLogo = document.getElementById('main-logo');
let vrAr = document.getElementById('vr-ar');
let xraybtn = document.getElementById("xraybutton");
let mainHero = document.getElementById("main-hero");
let expMrAr = document.getElementById('exp-mr-ar');
let expAr = document.getElementById('exp-ar');
let expMr = document.getElementById('exp-mr');
let mrVrAr = document.getElementById('mr-vr-ar');
let mrAr = document.getElementById('mr-ar');
let mrVr = document.getElementById('mr-vr');
let arVrMr = document.getElementById('ar-vr-mr');
let arMr = document.getElementById('ar-mr');
let arVr = document.getElementById('ar-vr');

let navCollapsed = false;

$('.navbar-collapse').on('show.bs.collapse', function () {
    navCollapsed = true;
    mainHero ? mainHero.style.display = 'none' : null
}).on('hide.bs.collapse', function () {
    $(this).removeClass("show");
    navCollapsed = false;
    mainHero ? mainHero.style.display = 'block' : null
})

// window.addEventListener('load', onVrViewLoad);
// window.addEventListener('load', onVrFunkyLoad);

// function onVrViewLoad() {
//     let vrView = new VRView.Player('#vrview', {
//       image: 'https://vhsoft.io/vr-360.png',
//       width: '100%',
//       height: 600
//     });
// }
// function onVrFunkyLoad() {
//     let vrView = new VRView.Player('#vrfunky', {
//         image: 'https://vhsoft.io/vr-360-funky4.png',
//         width: '100%',
//         height: 600
//       });
// }

window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (mainLogo) {
        if (currentScrollPos > '30') {
            mainLogo.style.display = 'none';
        } else {
            mainLogo.style.display = 'block';
        }
    }
    if (prevScrollpos > currentScrollPos || navCollapsed === true) {
        navbar.style.top = '0';
    } else {
        navbar.style.top = '-100px';
    } prevScrollpos = currentScrollPos;
};

if (vrAr) {
    vrAr.addEventListener('click', function () {
        if (document.querySelector('input[name = "vr-ar"]:checked')) {
            ar.style.display = 'block';
            vr.style.display = 'none';
        } else {
            vr.style.display = 'block';
            ar.style.display = 'none';
        }
    });
}

if (expMrAr) {
    expMrAr.addEventListener('click', function () {
        if (document.querySelector('input[name = "exp-mr-ar"]:checked')) {
            expAr.style.display = 'block';
            expMr.style.display = 'none';
        } else {
            expMr.style.display = 'block';
            expAr.style.display = 'none';
        }
    });
}


if (mrVrAr) {
    mrVrAr.addEventListener('click', function () {
        if (document.querySelector('input[name = "mr-vr-ar"]:checked')) {
            mrAr.style.display = 'block';
            mrVr.style.display = 'none';
        } else {
            mrVr.style.display = 'block';
            mrAr.style.display = 'none';
        }
    });
}

if (arVrMr) {
    arVrMr.addEventListener('click', function () {
        if (document.querySelector('input[name = "ar-vr-mr"]:checked')) {
            console.log("1")
            arMr.style.display = 'block';
            arVr.style.display = 'none';
        } else {
            console.log("2")
            arVr.style.display = 'block';
            arMr.style.display = 'none';
        }
    });
}

navButton.addEventListener('click', function () {
    navbar.style.top = '0';
});

window.addEventListener('resize', appHeight);
appHeight();

const validateForm = () => {
    let form = document.getElementById('contact-form');
    form.classList.add('was-validated');
    return form.checkValidity();
};

$(function () {
    window.verifyRecaptchaCallback = function (response) {
        $('input[data-recaptcha]').val(response).trigger('change')
    }

    window.expiredRecaptchaCallback = function () {
        $('input[data-recaptcha]').val("").trigger('change')
    }


    $('#contact-form').on('submit', function (e) {
        console.log("SUBMIT FORM")
        e.preventDefault();
        e.stopPropagation();

        if (validateForm()) {
            console.log("FORM VALIDATED")
            var url = 'contact.php';
            var  currentUrl = window.location.pathname;

            $.ajax({
                type: 'POST',
                url: url,
                data: $(this).serialize(),
                success: function (data) {
                    let form = document.getElementById('contact-form');
                    form.classList.remove('was-validated');
                    grecaptcha.reset();
                    if (data.type === 'success') {
                        $('#contact-form')[0].reset();
                            if (currentUrl == "/kontakt.html") {
                                window.location.href = window.location.origin + '/kontakt-sukces.html'
                            } else {
                                window.location.href = window.location.origin + '/contact-success.html'
                            }
                     } else {
                        $('#contact-form')[0].reset();
                            if (currentUrl == "/kontakt.html") {
                                window.location.href = window.location.origin + '/kontakt-problem.html'
                            } else {
                                window.location.href = window.location.origin + '/contact-problem.html'
                            }
                     }

                }
            });
            return false;
        }
    });
});

if (xraybtn) {
    xraybtn.addEventListener("click", xray);
}

function xray() {
    let xr = document.getElementById("xray");
console.log(xr.src)
    if (xr.src == "https://vhsoft.io/4500959dc0419c9dff08.png") {

        xr.src = "https://vhsoft.io/18d98d7b54ac9989761f.png";
    } else {
        xr.src = "https://vhsoft.io/4500959dc0419c9dff08.png";
    }
}






//   $(document).ready(function() {
//     $('#container-x').scroll(function() {
//       var scrollPosition = $('#container-x').scrollTop();

//       // Set the scroll position breakpoints based on your layout
//       var position1 = window.innerHeight/3;  // Adjust as needed
//       var position2 = window.innerHeight/3*2; // Adjust as needed
//       var position3 = window.innerHeight; // Adjust as needed


//       // Update config-scroll-number based on scroll position
//       if (scrollPosition < position1) {
//         $('.config-scroll-number').html("5");
//         // Change the image as needed
//         $('#config-scroll-img-1').removeClass('d-none').addClass('d-block');
//         $('#config-scroll-img-2, #config-scroll-img-3').removeClass('d-block').addClass('d-none')
//       } else if (scrollPosition < position2) {
//         $('.config-scroll-number').html("7");
//         // Change the image as needed
//         $('#config-scroll-img-1').removeClass('d-block').addClass('d-none')
//         $('#config-scroll-img-2').removeClass('d-none').addClass('d-block');;
//         $('#config-scroll-img-3').removeClass('d-block').addClass('d-none')
//       } else if (scrollPosition < position3) {
//         $('.config-scroll-number').html("10");
//         // Change the image as needed
//         $('#config-scroll-img-1, #config-scroll-img-2').removeClass('d-block').addClass('d-none')
//         $('#config-scroll-img-3').removeClass('d-none').addClass('d-block');;
//       }
//     });
//   });

